<template>
  <v-app>
    <div v-if="this.signedIn">

      <div class="home" v-if="!$vuetify.breakpoint.xs">
        <div class="lessonHeader">
          <h1 class="ma-0">Reference Tables</h1>
        </div>
      </div>

      <div class="greetingContainerMobile" v-if="$vuetify.breakpoint.xs">
        <h1 class="ma-0">Reference Tables</h1>
      </div>


      <div class="content">

        <p>Here you can view reference grammar tables for each of the cases.</p>
        <p>The declensions you have already learnt in lessons are shown in bold.</p>



        <v-expansion-panels>
          <v-expansion-panel style="margin: 0 0 20px 0">
            <v-expansion-panel-header color="#ffbe0b">
              <h2>Accusative</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">

              <h3>Singular Nouns</h3>
              <table border=1 >
                <col style="width:50%">
                <col style="width:50%">
                  <tr class="tableHeaderRow">
                    <td>
                      Ending / Description
                    </td>
                    <td>
                      How to Decline
                    </td>
                  </tr>
                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Masculine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 1, 0)">
                    <td> inanimate </td>
                    <td> no change </td>
                  </tr>
                  <tr :class="embolden(0, 1, 0)">
                    <td> animate, a consonant </td>
                    <td> +а </td>
                  </tr>
                  <tr :class="embolden(0, 1, 0)">
                    <td> animate, й </td>
                    <td> -й, +я </td>
                  </tr>
                  <tr :class="embolden(0, 1, 0)">
                    <td> animate, ь </td>
                    <td> -ь, +я </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Feminine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 1, 1)">
                    <td> а </td>
                    <td> -а, +у </td>
                  </tr>
                  <tr :class="embolden(0, 1, 1)">
                    <td> я </td>
                    <td> -я, +ю </td>
                  </tr>
                  <tr :class="embolden(0, 1, 1)">
                    <td> ь </td>
                    <td> no change </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Neuter
                    </td>
                  </tr>
                  <tr :class="embolden(0, 1, 2)">
                    <td> all nouns </td>
                    <td> no change </td>
                  </tr>
                </table>

                <br>

                <h3>Plural Nouns</h3>
                <table border=1 >
                  <col style="width:50%">
                  <col style="width:50%">
                    <tr class="tableHeaderRow">
                      <td>
                        Ending / Description (in nominative singular)
                      </td>
                      <td>
                        How to Decline
                      </td>
                    </tr>
                    <tr class="tableHeaderRowColoured">
                      <td colspan=2 >
                        Animate
                      </td>
                    </tr>
                    <tr class="tableHeaderRowColoured">
                      <td colspan=2 >
                        Masculine
                      </td>
                    </tr>
                    <tr :class="embolden(0, 1, 0)">
                      <td> ж, ч, ш, щ </td>
                      <td> +ей </td>
                    </tr>
                    <tr :class="embolden(0, 1, 0)">
                      <td> ц </td>
                      <td> +ев </td>
                    </tr>
                    <tr :class="embolden(0, 1, 0)">
                      <td> й </td>
                      <td> -й, +ев </td>
                    </tr>
                    <tr :class="embolden(0, 1, 0)">
                      <td> ь </td>
                      <td> -ь, +ей </td>
                    </tr>
                    <tr :class="embolden(0, 1, 0)">
                      <td> other </td>
                      <td> +ов </td>
                    </tr>

                    <tr class="tableHeaderRowColoured">
                      <td colspan=2 >
                        Feminine
                      </td>
                    </tr>
                    <tr :class="embolden(0, 1, 1)">
                      <td> а </td>
                      <td> -а </td>
                    </tr>
                    <tr :class="embolden(0, 1, 1)">
                      <td> consonant + я </td>
                      <td> -я, +ь </td>
                    </tr>
                    <tr :class="embolden(0, 1, 1)">
                      <td> vowel + я </td>
                      <td> -я, + й </td>
                    </tr>
                    <tr :class="embolden(0, 1, 1)">
                      <td> ь </td>
                      <td> -ь, +ей </td>
                    </tr>

                    <tr class="tableHeaderRowColoured">
                      <td colspan=2 >
                        Neuter
                      </td>
                    </tr>
                    <tr :class="embolden(0, 1, 2)">
                      <td> consonant + е </td>
                      <td> -е </td>
                    </tr>
                    <tr :class="embolden(0, 1, 2)">
                      <td> consonant + о </td>
                      <td> -o </td>
                    </tr>
                    <tr :class="embolden(0, 1, 2)">
                      <td> ое </td>
                      <td> -ое, +ых </td>
                    </tr>
                    <tr :class="embolden(0, 1, 2)">
                      <td> ее </td>
                      <td> -ее, +их </td>
                    </tr>

                    <tr class="tableHeaderRowColoured">
                      <td colspan=2 >
                        Inanimate (all genders)
                      </td>
                    </tr>
                    <tr :class="embolden(0, 1, 0)">
                      <td> all nouns </td>
                      <td> same as plural nominative </td>
                    </tr>
                  </table>

                <br>
                <h3>Adjectives - Inanimate</h3>
                <p>For the accusative inanimate case, there is no change from the nominative case.</p>



                <h3>Adjectives - Animate</h3>
                <table border=1 >
                  <col style="width:40%">
                  <col style="width:15%">
                  <col style="width:15%">
                  <col style="width:15%">
                  <col style="width:15%">
                    <tr class="tableHeaderRow tableHeaderRowColoured">
                      <td>
                        Ending in nominative masc.
                      </td>
                      <td>
                        Masculine
                      </td>
                      <td>
                        Feminine
                      </td>
                      <td>
                        Neuter
                      </td>
                      <td>
                        Plural
                      </td>
                    </tr>

                    <tr :class="embolden(1, 1, 0)">
                      <td> ый, ой </td>
                      <td> -ый / ой, +ого </td>
                      <td> -ый / ой, +ую </td>
                      <td> -ый / ой, +ое </td>
                      <td> -ый / ой, +ых </td>
                    </tr>
                    <tr :class="embolden(1, 1, 1)">
                      <td> ний (when unstressed) </td>
                      <td> -ий, +его </td>
                      <td> -ий, +юю </td>
                      <td> -ий, +ее </td>
                      <td> -ий, +их </td>
                    </tr>
                    <tr :class="embolden(1, 1, 2)">
                      <td> гий, кий, гой, кой, хой </td>
                      <td> -ий / ой, +ого </td>
                      <td> -ий / ой, +ую </td>
                      <td> -ий / ой, +ое </td>
                      <td> -ий / ой, +их </td>
                    </tr>
                    <tr :class="embolden(1, 1, 3)">
                      <td> жий, ший, чий, жой, шой, чой </td>
                      <td> -ий / ой, +его </td>
                      <td> -ий / ой, +ую </td>
                      <td> -ий / ой, +ее </td>
                      <td> -ий / ой, +их </td>
                    </tr>
                  </table>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>



        <v-expansion-panels>
          <v-expansion-panel style="margin: 0 0 20px 0">
            <v-expansion-panel-header color="#00b4d8">
              <h2>Prepositional</h2>

            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">

              <h3>Singular Nouns</h3>
              <table border=1 >
                <col style="width:50%">
                <col style="width:50%">
                  <tr class="tableHeaderRow">
                    <td>
                      Ending / Description
                    </td>
                    <td>
                      How to Decline
                    </td>
                  </tr>
                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Masculine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td> most nouns </td>
                    <td> +е </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td> ь </td>
                    <td> -ь, +е </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td> sometimes after prepositions в, на </td>
                    <td> +у </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Feminine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td> ь </td>
                    <td> -ь, +и </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td> а </td>
                    <td> -а, +е </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td> я </td>
                    <td> -я, +е </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td> ия </td>
                    <td> -ия, +ии </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Neuter
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td> о </td>
                    <td> -о, +е </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td> е </td>
                    <td> no change </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td> ие </td>
                    <td> -ие, +ии </td>
                  </tr>
                </table>

                <br>

                <h3>Plural Nouns</h3>
                <table border=1 >
                  <col style="width:50%">
                  <col style="width:50%">
                    <tr class="tableHeaderRow">
                      <td>
                        Ending / Description
                      </td>
                      <td>
                        How to Decline
                      </td>
                    </tr>
                    <tr class="tableHeaderRowColoured">
                      <td colspan=2 >
                        All genders
                      </td>
                    </tr>
                    <tr :class="embolden(0, 2, 0)">
                      <td> а </td>
                      <td> -а, +ах </td>
                    </tr>
                    <tr :class="embolden(0, 2, 0)">
                      <td> о </td>
                      <td> -о, +ах </td>
                    </tr>
                    <tr :class="embolden(0, 2, 0)">
                      <td> consonant </td>
                      <td> +ах </td>
                    </tr>
                    <tr :class="embolden(0, 2, 0)">
                      <td> others </td>
                      <td> -last letter, +ях </td>
                    </tr>


                  </table>

                  <br>


                <h3>Adjectives</h3>
                <table border=1 >
                  <col style="width:40%">
                  <col style="width:15%">
                  <col style="width:15%">
                  <col style="width:15%">
                  <col style="width:15%">
                    <tr class="tableHeaderRow tableHeaderRowColoured">
                      <td>
                        Ending in nominative masc.
                      </td>
                      <td>
                        Masculine
                      </td>
                      <td>
                        Feminine
                      </td>
                      <td>
                        Neuter
                      </td>
                      <td>
                        Plural
                      </td>
                    </tr>

                    <tr :class="embolden(1, 2, 0)">
                      <td> ый, ой </td>
                      <td> -ый / ой, +ом </td>
                      <td> -ый / ой, +ой </td>
                      <td> -ый / ой, +ом </td>
                      <td> -ый / ой, +ых </td>
                    </tr>
                    <tr :class="embolden(1, 2, 1)">
                      <td> ний (when unstressed) </td>
                      <td> -ий, +ем </td>
                      <td> -ий, +ей </td>
                      <td> -ий, +ем </td>
                      <td> -ий, +их </td>
                    </tr>
                    <tr :class="embolden(1, 2, 2)">
                      <td> гий, кий, гой, кой, хой </td>
                      <td> -ий / ой, +ом </td>
                      <td> -ий / ой, +ой </td>
                      <td> -ий / ой, +ом </td>
                      <td> -ий / ой, +их </td>
                    </tr>
                    <tr :class="embolden(1, 2, 3)">
                      <td> жий, ший, чий, жой, шой, чой </td>
                      <td> -ий / ой, +ем </td>
                      <td> -ий / ой, +ей </td>
                      <td> -ий / ой, +ем </td>
                      <td> -ий / ой, +их </td>
                    </tr>
                  </table>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>



        <v-expansion-panels>
          <v-expansion-panel style="margin: 0 0 20px 0">
            <v-expansion-panel-header color="#e73265">
              <h2>Genitive</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">

              <h3>Singular Nouns</h3>
              <table border=1 >
                <col style="width:50%">
                <col style="width:50%">
                  <tr class="tableHeaderRow">
                    <td>
                      Ending / Description
                    </td>
                    <td>
                      How to Decline
                    </td>
                  </tr>
                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Masculine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 3, 0)">
                    <td> consonant </td>
                    <td> +а </td>
                  </tr>
                  <tr :class="embolden(0, 3, 0)">
                    <td> й </td>
                    <td> -й, +я </td>
                  </tr>
                  <tr :class="embolden(0, 3, 0)">
                    <td> ь </td>
                    <td> -ь, +я </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Feminine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 3, 1)">
                    <td> а </td>
                    <td> -а, +ы </td>
                  </tr>
                  <tr :class="embolden(0, 3, 1)">
                    <td> ка, га, ха, жа, ша, ща, ча </td>
                    <td> -а, +и </td>
                  </tr>
                  <tr :class="embolden(0, 3, 1)">
                    <td> я </td>
                    <td> -я, +и </td>
                  </tr>
                  <tr :class="embolden(0, 3, 1)">
                    <td> ь </td>
                    <td> -ь, +и </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Neuter
                    </td>
                  </tr>
                  <tr :class="embolden(0, 3, 2)">
                    <td> о </td>
                    <td> -о, +а </td>
                  </tr>
                  <tr :class="embolden(0, 3, 2)">
                    <td> е </td>
                    <td> -е, +я </td>
                  </tr>
                  <tr :class="embolden(0, 3, 2)">
                    <td> це, же, ше, ще </td>
                    <td> -е, +а </td>
                  </tr>
                </table>

                <br>

              <h3>Plural Nouns</h3>
              <table border=1 >
                <col style="width:50%">
                <col style="width:50%">
                  <tr class="tableHeaderRow">
                    <td>
                      Ending / Description
                    </td>
                    <td>
                      How to Decline
                    </td>
                  </tr>
                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Masculine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 3, 0)">
                    <td> ж, ч, ш, щ </td>
                    <td> +ей </td>
                  </tr>
                  <tr :class="embolden(0, 3, 0)">
                    <td> ь </td>
                    <td> -ь, +ей </td>
                  </tr>
                  <tr :class="embolden(0, 3, 0)">
                    <td> й </td>
                    <td> -й, +ев </td>
                  </tr>
                  <tr :class="embolden(0, 3, 0)">
                    <td> ц (unstressed) </td>
                    <td> +ев </td>
                  </tr>
                  <tr :class="embolden(0, 3, 0)">
                    <td> ц (stressed) </td>
                    <td> +ов </td>
                  </tr>
                  <tr :class="embolden(0, 3, 0)">
                    <td> other </td>
                    <td> +ов </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Feminine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 3, 1)">
                    <td> а </td>
                    <td> -а </td>
                  </tr>
                  <tr :class="embolden(0, 3, 1)">
                    <td> consonant + я </td>
                    <td> -я, +ь </td>
                  </tr>
                  <tr :class="embolden(0, 3, 1)">
                    <td> vowel + я </td>
                    <td> -я, +й </td>
                  </tr>
                  <tr :class="embolden(0, 3, 1)">
                    <td> ь </td>
                    <td> -ь, +ей </td>
                  </tr>
                  <tr :class="embolden(0, 3, 1)">
                    <td> ья </td>
                    <td> -ья, +ей </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Neuter
                    </td>
                  </tr>
                  <tr :class="embolden(0, 3, 2)">
                    <td> о </td>
                    <td> -о </td>
                  </tr>
                  <tr :class="embolden(0, 3, 2)">
                    <td> ие </td>
                    <td> -ие, +ий </td>
                  </tr>
                  <tr :class="embolden(0, 3, 2)">
                    <td> ье </td>
                    <td> -ье, +ий </td>
                  </tr>
                  <tr :class="embolden(0, 3, 2)">
                    <td> consonant + е </td>
                    <td> -е </td>
                  </tr>
                </table>

                <br>

              <h3>Adjectives</h3>
              <table border=1 >
                <col style="width:40%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                  <tr class="tableHeaderRow tableHeaderRowColoured">
                    <td>
                      Ending in nominative masc.
                    </td>
                    <td>
                      Masculine
                    </td>
                    <td>
                      Feminine
                    </td>
                    <td>
                      Neuter
                    </td>
                    <td>
                      Plural
                    </td>
                  </tr>

                  <tr :class="embolden(1, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(1, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(1, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(1, 2, 3)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                </table>



            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>



        <v-expansion-panels>
          <v-expansion-panel style="margin: 0 0 20px 0">
            <v-expansion-panel-header color="#90be6d">
              <h2>Dative</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">

              <h3>Singular Nouns</h3>
              <table border=1 >
                <col style="width:50%">
                <col style="width:50%">
                  <tr class="tableHeaderRow">
                    <td>
                      Ending / Description
                    </td>
                    <td>
                      How to Decline
                    </td>
                  </tr>
                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Masculine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Feminine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Neuter
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                </table>

                <br>

              <h3>Plural Nouns</h3>
              <table border=1 >
                <col style="width:50%">
                <col style="width:50%">
                  <tr class="tableHeaderRow">
                    <td>
                      Ending / Description
                    </td>
                    <td>
                      How to Decline
                    </td>
                  </tr>
                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Masculine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Feminine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Neuter
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                </table>

                <br>

              <h3>Adjectives</h3>
              <table border=1 >
                <col style="width:40%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                  <tr class="tableHeaderRow tableHeaderRowColoured">
                    <td>
                      Ending in nominative masc.
                    </td>
                    <td>
                      Masculine
                    </td>
                    <td>
                      Feminine
                    </td>
                    <td>
                      Neuter
                    </td>
                    <td>
                      Plural
                    </td>
                  </tr>

                  <tr :class="embolden(1, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(1, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(1, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(1, 2, 3)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                </table>


            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>



        <v-expansion-panels>
          <v-expansion-panel style="margin: 0 0 20px 0">
            <v-expansion-panel-header color="#ada7c9">
              <h2>Instrumental</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">

              <h3>Singular Nouns</h3>
              <table border=1 >
                <col style="width:50%">
                <col style="width:50%">
                  <tr class="tableHeaderRow">
                    <td>
                      Ending / Description
                    </td>
                    <td>
                      How to Decline
                    </td>
                  </tr>
                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Masculine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Feminine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Neuter
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                </table>

                <br>

              <h3>Plural Nouns</h3>
              <table border=1 >
                <col style="width:50%">
                <col style="width:50%">
                  <tr class="tableHeaderRow">
                    <td>
                      Ending / Description
                    </td>
                    <td>
                      How to Decline
                    </td>
                  </tr>
                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Masculine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Feminine
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                  </tr>

                  <tr class="tableHeaderRowColoured">
                    <td colspan=2 >
                      Neuter
                    </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(0, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                  </tr>
                </table>

                <br>

              <h3>Adjectives</h3>
              <table border=1 >
                <col style="width:40%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                  <tr class="tableHeaderRow tableHeaderRowColoured">
                    <td>
                      Ending in nominative masc.
                    </td>
                    <td>
                      Masculine
                    </td>
                    <td>
                      Feminine
                    </td>
                    <td>
                      Neuter
                    </td>
                    <td>
                      Plural
                    </td>
                  </tr>

                  <tr :class="embolden(1, 2, 0)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(1, 2, 1)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(1, 2, 2)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                  <tr :class="embolden(1, 2, 3)">
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                    <td>  </td>
                  </tr>
                </table>


            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>




      </div>
    </div>
  </v-app>
</template>

<script>
import {db} from '../firebase/db';
import firebase from 'firebase';

export default {
  title: "Reference",
  props: [],
  data() {
    return {
      signedIn: false,
      user: [],
      lessonsCompleted: [],
      lessonsCompletedAdjectives: [],
      userData: [],
      stats: []
    }
  },
  methods: {
    async getLessonsCompleted() {
      this.userData = await db.collection('Users').doc(this.uid).get();
      this.userData = this.userData.data();
      this.lessonsCompleted = this.userData.lessonsCompleted;
      this.lessonsCompletedAdjectives = this.userData.lessonsCompletedAdjectives;

      // console.log(this.lessonsCompleted);
    },

    embolden(wordType, caseIndex, type) {
      var wordTypes = ['lessonsCompleted', 'lessonsCompletedAdjectives'];

      if (this[wordTypes[wordType]][caseIndex] > type) {
        return 'bold'
      } else {
        return 'greyed-out'
      }
    }



  },
  async beforeMount() {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.user = user;
        this.uid = user.uid;
        this.username = user.displayName;
        this.signedIn = true

        this.getLessonsCompleted()

      } else {
        // User is signed out
        // ...
        this.signedIn = false;
        this.$router.push({ name: 'Index'})
      }
    });



  }
}
</script>


<style scoped>
  h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }

  h2 {
    color: #fff
  }

  /* .v-expansion-panel::before {
    box-shadow: none;
  } */


  .home {
    height: 180px;
    text-align: left;
    background-color: #383838;
    background-image: url(../assets/blini.png);
    background-size: 160px;
    background-repeat: no-repeat;
    background-position: calc(50vw + 320px) 0px;
    position: relative;
    align-content: center;
  }

  .lessonHeader {
    color: #fff;
    max-width: 1000px;
    font-size: 1.2em;
    /* margin: auto; */
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: calc(50vw - 500px)
  }

  .content {
    padding: 20px 0 0 0;
    text-align: left;
    max-width: 1000px;
    margin: auto;
  }

  .caseIcon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: auto;
  color: #fff;
  border: 3px solid rgba(0,0,0,0.75);
  background-size: 200%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}

.pageButton {
  font-size: 32px;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border-color: #383838;
}

.tableHeaderRow {
  font-size: 1.2em;
}

.tableHeaderRowColoured {
  background-color: #dedede;
  font-size: 1.1em;
}

/* table td {
  width: 50%;
} */

.bold {
  font-weight: bold;
}

.greyed-out {
  color: #dedede;
}
</style>








<style scoped>
  @media screen and (max-width: 1024px) {

    h1 {
      font-size: 2em;
      margin-bottom: 20px;
    }

    tr, th, td {
      font-size: 0.8em;
    }

    /* .tableHeaderRow {
      font-size: 1.2em;
    }

    .tableHeaderRowColoured {
      background-color: #dedede;
      font-size: 1.1em;
    } */

    .greetingContainerMobile {
      height: auto;
      background-color: #383838;
      color: #fff;
      text-align: left;
      padding: 10px;
    }

    .content {
      padding: 10px;
    }




  }
</style>















<!--  -->
